export const useToasty = () => {
  return {
    success: (message: string) => {
      useToast().add({
        title: message,
      })
    },
    error: (message: string) => {
      useToast().add({
        color: 'red',
        title: message,
        icon: 'i-ph-seal-warning',
      })
    }
  }
}
